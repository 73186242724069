module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"ZzOxG4L2rht7BdsTxIMUYS2kzct2OjG7","devKey":"SEGMENT_DEV_WRITE_KEY","trackPage":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#42BA96","showSpinner":false},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/locale","languages":["en","de"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
